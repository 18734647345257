import { useMemo } from 'react';
// routes
import { paths } from '../../routes/paths';
// components
import { Co2 } from '@mui/icons-material';
import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  alert: icon('ic_alert'),
  shield: icon('ic_shield'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        subheader: 'General',
        items: [
          {
            title: 'dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: 'users',
            path: paths.dashboard.users.root,
            icon: ICONS.user,
          },
          {
            title: 'clients',
            path: paths.dashboard.clients.root,
            icon: ICONS.analytics,
          },
          {
            title: 'events',
            path: paths.dashboard.events.root,
            icon: ICONS.mail,
          },
          {
            title: 'bookings',
            path: paths.dashboard.bookings.root,
            icon: ICONS.booking,
          },
          {
            title: 'shipments',
            path: paths.dashboard.shipments.root,
            icon: ICONS.order,
          },
          {
            title: 'emission-reports',
            path: paths.dashboard.emissionReports.root,
            icon: <Co2 />,
          },
          {
            title: 'contracts',
            path: paths.dashboard.contracts.root,
            icon: ICONS.folder,
          },
          {
            title: 'quotations',
            path: paths.dashboard.quotations.root,
            icon: ICONS.invoice,
          },
          {
            title: 'quoteRequests',
            path: paths.dashboard.quoteRequests.root,
            icon: ICONS.external,
          },
          {
            title: 'Release Notes',
            path: paths.dashboard.releaseNotes.root,
            icon: ICONS.file,
          },
          {
            title: 'Maintenance & Alerts',
            path: paths.dashboard.maintenance.root,
            icon: ICONS.alert,
          },
          {
            title: 'Features',
            path: paths.dashboard.features.root,
            icon: ICONS.job,
          },
          {
            title: 'Terms & Conditions',
            path: paths.dashboard.termsAndConditions.root,
            icon: ICONS.shield,
          },
        ],
      },
    ],
    [],
  );

  return data;
}
